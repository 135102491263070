import React from 'react';

export const PortalContext = React.createContext({} as any);
type Props = {
    children: React.ReactNode;
}

export const PortalProvider = ({
    children
}: Props) => {
    const initialData = {
        cloud_account: {
          filterData: {},
          selectedListData: [],
        }
      };
    const [portalData, setPortalData] = React.useState(initialData as any);
    return (
        <PortalContext.Provider value={{portalData, setPortalData}}>
            {children}
        </PortalContext.Provider>
    );
};

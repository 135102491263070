import React, { useState } from 'react';
import { useRouteRef, microsoftAuthApiRef, alertApiRef } from '@backstage/core-plugin-api';
import {
    useForm,
    Controller
} from 'react-hook-form';
import { useStyles } from './styles';

import {
    Button,
    TextField,
    CircularProgress,
    Card,
    CardContent,
    Divider,
    Select,
    MenuItem,
    InputLabel,
    Grid,
    Paper,
    Box,
    FormHelperText,
    FormControl
} from '@material-ui/core';
import { useAsync } from 'react-use';

import { PageLayout } from 'tmna-ui-kit';
import { Content } from '@backstage/core-components';
import { costCenterApiRef } from '@internal/plugin-cost-center/src/api';
import {
    useApi,
} from '@backstage/core-plugin-api';
import { CustomAlertDisplay, CustomAlertMessage } from './CustomAlertDisplay';
import { useLocation, useNavigate } from 'react-router-dom';
import { _values } from 'tmna-utils';
import { Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import { rootRouteRef } from '../../routes';
import { creationDate } from '../AccountDetails';
import { useUserProfile } from '@internal/plugin-user-settings-fork';


// const NonEditableTextField: FC<{
//     gridProps?: GridProps;
//     control: Control;
//     name: any;
//     label: TextFieldProps['label'];
// }> = ({ gridProps = {}, control, name, label }) => (
//     <Grid item xs={4} {...gridProps}>
//         <Controller
//             {...{ control, name }}
//             render={({ field: { value } }) => (
//                 <TextField {...{ label, value }} disabled />
//             )}
//         />
//     </Grid>
// );




export const BreakGlassRequestForm = () => {
    const classes = useStyles();
    
    const authref = useApi(microsoftAuthApiRef);
    const costCenterApi = useApi(costCenterApiRef);
    const location: any = useLocation();
    const navigate = useNavigate();
    // const { state: routerState } = location;
    // const [routerValues] = React.useState(routerState as any);

    const [sessionTime, setSessionTime] = React.useState('');
    const [businessJustification, setBusinessJustification] = React.useState('');
    const [serviceNowID, setServiceNowID] = React.useState('');
    const [saveLoading, setSaveLoading] = React.useState(false);
    const alertApi = useApi(alertApiRef);
    const [disableSessionTime, setDisableSessionTime] = React.useState(false);
    const [disableBusinessJustification, setDisableBusinessJustification] = React.useState(false);
    const [disableServiceNowID, setDisableServiceNowID] = React.useState(false);
    const [showBackToAccountDetailsButton, setShowBackToAccountDetailsButton] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [openConfirmation, setOpenConfirmation] = React.useState(false);
    const { profile, displayName } = useUserProfile();
    const [messages, setMessages] = useState<Array<CustomAlertMessage>>([]);

    const triggerAlert = (alert: CustomAlertMessage) =>
        setMessages(alerts => alerts.concat(alert));

    const rootLink = useRouteRef(rootRouteRef);
    const backToLink = React.useMemo(
        () => ({
            to: rootLink(),
            label: 'Back to Cloud Account Master',
        }),
        [rootLink],
    );
    //Initialize the CAM attributes
    const { control, setValue, formState: { errors, isDirty } } = useForm<any>({
        mode: 'onChange',
        defaultValues: {
            serviceNowID: '',
            businessJustification: '',
            sessionTime: ''
        },
    });

    const {
        value: _defaultLoadData = {} as any,
        loading: defaultLoadDataLoading,
    } = useAsync(async () => {
        let defaultData: any;

        //Define access to different sections of the edit page based on the user role
        setValue('genLedId', '6172320004');
        setValue('serviceNowID', '');
        setValue('businessJustification', '');
        setValue('sessionTime', '');
        //Populate attributes with the existing values fetched from get account call
        if (defaultData && defaultData.length > 0) {
            setValue('accId', defaultData[0]?.account_number);
            setValue('accName', defaultData[0]?.account_name);
            setValue('rootEmailId', defaultData[0]?.root_email);
            setValue('appName', defaultData[0]?.app_name === '' ? 'NULL' : defaultData[0]?.app_name);
            setValue('appServiceId', defaultData[0]?.app_id);
            setValue('businessUnitsL2', defaultData[0]?.bu_l2);
            setValue('businessUnitsL3', defaultData[0]?.bu_l3);
            setValue('mse', defaultData[0]?.monthly_estimate !== 0 ? defaultData[0]?.monthly_estimate : '');
            setValue('wbsId', defaultData[0]?.wbs_id);
            setValue('costCenter', defaultData[0]?.cost_center);
            setValue('genLedId', defaultData[0]?.gl_id);
            setValue('compCode', defaultData[0]?.company_code);
            setValue('notes', defaultData[0]?.comments);
            setValue('created', creationDate(defaultData[0]?.created));
            setValue('created_by', defaultData[0]?.created_by);
            setValue('modified', creationDate(defaultData[0]?.modified));
            //requiredCheckForTMNA(defaultData[0]?.root_org);

            const Owner: any = defaultData[0]?.contacts
                .filter((item: any) => {
                    return item.type === 'Owner';
                }).sort((a: any, b: any) => a.name.localeCompare(b.name))
                .map((data: { name: any; email: any }) => ({
                    displayName: data?.name,
                    mail: data?.email,
                    isExist: true,
                }));
            setValue('accOwner', Owner);

            const Sponsor: any = defaultData[0]?.contacts
                .filter((item: any) => {
                    return item.type === 'Sponsor';
                }).sort((a: any, b: any) => a.name.localeCompare(b.name))
                .map((data: { name: any; email: any }) => ({
                    displayName: data?.name,
                    mail: data?.email,
                    isExist: true,
                }));

            setValue('finSponsor', Sponsor);

            const Technical: any = defaultData[0]?.contacts
                .filter((item: any) => {
                    return item.type === 'Technical';
                }).sort((a: any, b: any) => a.name.localeCompare(b.name))
                .map((data: { name: any; email: any }) => ({
                    displayName: data?.name,
                    mail: data?.email,
                    isExist: true,
                }));
            setValue('techContact', Technical);

            return { dataShow: defaultData[0] };
        }
        return { dataShow: defaultData };
    }, []);

    function convertToMinutes(sessionTime: string) {
        if (sessionTime === '0.5 hours') {
            return '0:30';
        }
        else if (sessionTime === '1 hour') {
            return '1:00';
        }
        else if (sessionTime === '1.5 hours') {
            return '1:30';
        }
        else if (sessionTime === '2 hours') {
            return '2:00';
        }
        else if (sessionTime === '2.5 hours') {
            return '2:30';
        }
        else if (sessionTime === '3 hours') {
            return '3:00';
        }
        else if (sessionTime === '3.5 hours') {
            return '3:30';
        }
        else if (sessionTime === '4 hours') {
            return '4:00';
        }
        else {
            return '0:00';
        }
    }

    const handleClose = (msg: string) => {
        if (msg == "yes") {
            setOpen(false);
            navigate(-1)
        } else {
            setOpen(false);
        }
    };
    const handleCloseSave = () => {
        setOpenConfirmation(false);
    };
    const submitFunction = async () => {
        setSaveLoading(true);
        const accessTokenValue = await authref.getAccessToken();
        console.log(displayName)

        try {
            const response = await costCenterApi.postBreakGlassRequest({
                accountId: location.state.accountId,
                serviceNowTask: serviceNowID,
                businessJustification: businessJustification,
                sessionTime: convertToMinutes(sessionTime),
                requestedBy: {
                    name: profile.displayName ?? '',
                    email: profile.email ?? '',
                }
            }, accessTokenValue);
            console.log(response);
            if (response.status === 200) { //figure out how to get the response code instead of this
                console.log("SUCCESS");
                triggerAlert({
                    message: (
                        <React.Fragment>
                            Request Submitted Successfully. The account {location.state.accountId} has been granted Admin access role for {sessionTime}. To log into Britive, click <a href="https://tmna.britive-app.com/my-access" target="_blank" rel="noopener" style={{ color: 'blue', textDecoration: 'underline' }}>here</a>.
                        </React.Fragment>
                    ), 
                    severity: 'success',
                    display: 'permanent',
                });
                setDisableBusinessJustification(true);
                setDisableSessionTime(true);
                setDisableServiceNowID(true);
                setSaveLoading(false);
                setShowBackToAccountDetailsButton(true);
            } else {
                console.error("An error has occurred while processing your request. Please try again later.");
                alertApi.post({
                    message: response.message,
                    severity: 'error',
                    display: 'permanent',
                });
            }
        } catch (error) {
            // console.log("error", error);
            // console.error("An error has occurred while processing your request. Please try again later.");
            // alertApi.post({
            //     message: "An error has occurred while processing your request. Please try again later.",
            //     severity: 'error',
            //     display: 'permanent',
            // });
        } finally {
            setSaveLoading(false);
        }
    }
    return (
        <PageLayout
            title={
                <Typography style={{ fontSize: '32px', fontWeight: 'bold' }}>
                    {"Break Glass Request Form for AWS Account: " + location.state.accountId}
                </Typography>
            }
            subtitle=""
            headerBackgroundImg={'/assets/service-catalog_bg.png'}
            backToLink={backToLink}
            displayButton={true}
        >
            <Dialog
                open={openConfirmation}
                onClose={() => handleCloseSave()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="xl"
            >

                <DialogActions style={{ backgroundColor: '#ccffcc' }}>
                    <Grid container justifyContent="center" alignItems="center" style={{ paddingBottom: '6px' }}>
                        <Button
                            variant="contained"
                            onClick={() => handleCloseSave()}
                            color="primary"
                            autoFocus
                        >
                            OK
                        </Button>
                    </Grid>
                </DialogActions>
            </Dialog>
            {/* {
                <div className={classes.customAlert}><Alert severity="error">Edits made cannot be saved until the mandatory fields are completed. Please review errors highlighted in red text.</Alert>  </div>
            } */}
            <form>
                <Paper className={classes.root} data-testid="tabsHeader" style={{
                    display: 'flex', padding: "0px 24px",
                    justifyContent: "space-between"
                }}>
                    <div style={{ width: '100%' }}>
                    </div>
                    <div className={classes.actionBtnDiv}>
                        <Dialog
                            open={open}
                            onClose={() => handleClose("no")}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogContent>
                                <Typography gutterBottom>
                                    You have unsaved edits. Would you like to discard changes?
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => handleClose("no")} color="primary" variant="contained" style={{
                                    color: '#000000',
                                    boxShadow: '0 0 0 2px #000000 inset',
                                    marginRight: '16px',
                                    background: 'white',
                                }}>
                                    No
                                </Button>
                                <Button onClick={() => handleClose("yes")} variant="contained" color="primary" autoFocus>
                                    Yes
                                </Button>
                            </DialogActions>
                        </Dialog>

                        {/* <Dialog
                            open={openSave}
                            onClose={() => handleCloseSave()}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            maxWidth="xl"
                        >
                            <DialogContent>
                                <Typography gutterBottom>
                                    Are you sure you want to save the changes?
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => handleCloseSave()} color="primary" variant="contained" style={{
                                    color: '#000000',
                                    boxShadow: '0 0 0 2px #000000 inset',
                                    marginRight: '16px',
                                    background: 'white',
                                }}>
                                    No
                                </Button>
                                <Button variant="contained"
                                    onClick={() => handleCloseSave()} color="primary" autoFocus>
                                    Yes
                                </Button>
                            </DialogActions>
                        </Dialog> */}
                    </div>
                </Paper>
                <div id="headTop" className={classes.tabContent}>
                    <Content>
                        <Card className={classes.root} style={{ marginTop: "1rem" }}>
                            {defaultLoadDataLoading && (
                                <div className={classes.spinner}>
                                    <CircularProgress color="secondary" />
                                </div>
                            )}
                            {defaultLoadDataLoading === false && (
                                <CardContent>
                                    <div className={classes.header}>
                                        <span
                                            style={{
                                                fontWeight: 'bold',
                                                fontSize: '25px',
                                                lineHeight: '2.5',
                                                marginTop: '8px',
                                            }}
                                        >
                                            Break Glass Details
                                        </span>{' '}
                                    </div>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <Grid item id="RequestInformation">
                                                <Divider orientation="horizontal" />
                                            </Grid>
                                            <Grid item xs={12} className={classes.rowMargin}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={11} data-testid="CloudAccountID">
                                                        <InputLabel shrink id="cloud-account-id-label" style={{ fontSize: '14px' }}>
                                                            Cloud Account ID
                                                        </InputLabel>
                                                        <Controller
                                                            control={control}
                                                            name="CloudAccountID"
                                                            render={({ field: { onChange } }: any) => (
                                                                <TextField
                                                                    style={{
                                                                        width: '50%',
                                                                        fontSize: '14px',
                                                                    }}
                                                                    value={location.state.accountId}
                                                                    onChange={onChange}
                                                                    disabled={true}
                                                                    variant="outlined"
                                                                    InputLabelProps={{
                                                                        style: { fontSize: '14px' },
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={11} data-testid="ServiceNowID">
                                                        <InputLabel shrink id="snow-id-label" style={{ fontSize: '14px' }}>
                                                            ServiceNow Change Request ID <span style={{ color: 'red' }}>*</span>
                                                        </InputLabel>
                                                        <Controller
                                                            control={control}
                                                            name="ServiceNowID"
                                                            rules={{
                                                                required: 'ServiceNow ID is required',
                                                                pattern: {
                                                                    value: /^(INC\d{8}|CTASK\d{7}|TASK\d{8})$/,
                                                                    message: 'Must be a valid ServiceNow ID',
                                                                },
                                                            }}
                                                            render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                                                <TextField
                                                                    style={{
                                                                        width: '50%',
                                                                        fontSize: '14px',
                                                                    }}
                                                                    error={!!error}
                                                                    helperText={error?.message ?? "Incident #, Change Task #, or Task # from the ServiceNow ticket related to this request."}
                                                                    value={value}
                                                                    onChange={(e) => {
                                                                        onChange(e);
                                                                        setServiceNowID(e.target.value as string);
                                                                    }}
                                                                    disabled={disableServiceNowID}
                                                                    variant="outlined"
                                                                    InputLabelProps={{
                                                                        style: { fontSize: '14px' },
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={11} data-testid="BusinessJustification">
                                                        <InputLabel shrink id="business-justification-label" style={{ fontSize: '14px' }}>
                                                            Business Justification <span style={{ color: 'red' }}>*</span>
                                                        </InputLabel>
                                                        <Controller
                                                            control={control}
                                                            name="BusinessJustification"
                                                            rules={{
                                                                required: 'Business Justification is required',
                                                            }}
                                                            render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                                                <TextField
                                                                    style={{ width: '50%' }}
                                                                    multiline
                                                                    minRows={2}
                                                                    value={value}
                                                                    onChange={(e) => {
                                                                        onChange(e);
                                                                        setBusinessJustification(e.target.value as string);
                                                                    }}
                                                                    disabled={disableBusinessJustification}
                                                                    error={!!error}
                                                                    helperText={error?.message ?? "Please specify why Break Glass is being requested, clarifying why IaC cannot be used to make the necessary changes."}
                                                                    variant="outlined"
                                                                    maxRows={4}
                                                                    InputLabelProps={{
                                                                        style: { fontSize: '14px' },
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={11} data-testid="SessionTime">
                                                        <InputLabel shrink id="session-time-label" style={{ fontSize: '14px' }}>
                                                            Session Time <span style={{ color: 'red' }}>*</span>
                                                        </InputLabel>
                                                        <Controller
                                                            control={control}
                                                            rules={{
                                                                required: 'Session Time is required',
                                                            }}
                                                            name="SessionTime"
                                                            render={({ field: { value, onChange }, fieldState: { error } }) => (
                                                                <FormControl fullWidth error={!!error}>
                                                                    <Select
                                                                        fullWidth
                                                                        className={classes.customWidth}
                                                                        id="bul3-select"
                                                                        value={value}
                                                                        onChange={(e) => {
                                                                            onChange(e);
                                                                            setSessionTime(e.target.value as string);
                                                                        }}
                                                                        variant="outlined"
                                                                        disabled={disableSessionTime}
                                                                    >
                                                                        <MenuItem value="0.5 hours">0.5 hours</MenuItem>
                                                                        <MenuItem value="1 hour">1 hour</MenuItem>
                                                                        <MenuItem value="1.5 hours">1.5 hours</MenuItem>
                                                                        <MenuItem value="2 hours">2 hours</MenuItem>
                                                                        <MenuItem value="2.5 hours">2.5 hours</MenuItem>
                                                                        <MenuItem value="3 hours">3 hours</MenuItem>
                                                                        <MenuItem value="3.5 hours">3.5 hours</MenuItem>
                                                                        <MenuItem value="4 hours">4 hours</MenuItem>
                                                                    </Select>
                                                                    <FormHelperText>{error ? error.message : "Length of time that Console access is required."}</FormHelperText>
                                                                </FormControl>
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={11} container alignItems="center" spacing={2}>
                                                        <Grid item>
                                                            <Box display="flex" alignItems="center">
                                                                <InputLabel shrink id="session-time-label" style={{ fontSize: '14px', marginRight: '8px' }}>
                                                                    Requestor:
                                                                </InputLabel>
                                                                <InputLabel shrink id="session-time-label" style={{ fontSize: '14px', marginRight: '8px', fontWeight: 'normal' }}>
                                                                    {profile?.displayName}
                                                                </InputLabel>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                    {!showBackToAccountDetailsButton ? (
                                                        <Grid item xs={11} data-testid="Buttons">
                                                            <Button
                                                                onClick={() => navigate(
                                                                    `/cloud-account/CloudAccountDetails?accountId=${location.state.accountId}`,
                                                                  )}
                                                                variant="contained"
                                                                color="secondary"
                                                                style={{
                                                                    color: '#000000',
                                                                    boxShadow: '0 0 0 2px #000000 inset',
                                                                    marginRight: '16px',
                                                                    background: 'white',
                                                                }}
                                                            >
                                                                Cancel
                                                            </Button>
                                                            <Button
                                                                className={classes.saveBtn}
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={() => submitFunction()}
                                                                disabled={!isDirty || saveLoading || Object.keys(errors).length > 0 || sessionTime === '' || businessJustification === '' || serviceNowID === ''}
                                                            >
                                                                {saveLoading ? (
                                                                    <CircularProgress color="secondary" size={24} />
                                                                ) : (
                                                                    'Submit'
                                                                )}
                                                            </Button>
                                                        </Grid>
                                                    ) : (
                                                        <Button
                                                            onClick={() => navigate(-1)}
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Finish
                                                        </Button>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            )}
                        </Card>
                    </Content>
                </div>
            </form>
            <CustomAlertDisplay messages={messages} setMessages={setMessages} />
        </PageLayout >
    );
};

